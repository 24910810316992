import React from "react"
import styled, { css } from "styled-components"

import Layout from "../components/archive/layout"
import SEO from "../components/archive/seo"
import Section from "../components/archive/section"
import Grid from "../components/archive/lib/grid"

const H2 = styled.h2`
  font-size: 2.4rem;
  line-height: 3rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: ${props => props.theme.verticalSpacing(2)};
  font-weight: 300;
`

const H3 = styled.h3`
  font-size: 1.6rem;
  line-height: 2rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  margin: ${props => props.theme.verticalSpacing(1)} 0;
`

const ContentText = styled.p`
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin: 0;
  margin-top: ${props => props.theme.verticalSpacing(1)};
  margin-bottom: ${props => props.theme.verticalSpacing(1)};
`

const Privacy = () => (
  <Layout>
    <SEO title="Privacy" />
    <Section
      css={css`
        font-size: 1.8rem;
        line-height: 2.4rem;
      `}
    >
      <Grid>
        <Grid.Row>
          <Grid.Col>
            <h1
              css={css`
                font-size: 4rem;
                line-height: 6.4rem;
                font-weight: 300;
              `}
            >
              Pumpjack and Privacy
            </h1>
            <H2>The Highlights</H2>
            <ContentText>
              Pumpjack Dataworks, Inc. and our subsidiaries (“Pumpjack
              Dataworks” or “we”) are committed to complying with data
              protection legislation, including the data protection regime
              introduced by the General Data Protection Regulation (EU
              Regulation 2016/679). This privacy notice contains information on
              what personal data we collect, what we do with that information,
              and what rights you have.
            </ContentText>
            <H2>The Details</H2>
            <H3>Introduction</H3>
            <ContentText>
              We respect your privacy and are committed to protecting it through
              our compliance with this Privacy and Security Statement. This
              Privacy and Security Statement describes the types of information
              we may collect from you or that you may provide—for example, when
              you visit any of our websites and mobile applications or contact
              us—and how we use, protect, and disclose that information.
            </ContentText>
            <ContentText>
              This Privacy and Security Statement does not apply to information
              collected on any third-party site or application (including
              advertising) that may link to or be accessible from our websites
              and mobile applications. We are not responsible for the privacy
              policies or data collection, use and disclosure practices of those
              sites. We encourage you to review the privacy policies of each
              site you visit.
            </ContentText>
            <ContentText>
              Please read this Privacy and Security Statement carefully to
              understand our policies and practices regarding your information
              and how we will treat it. By accessing or using any of our
              websites and mobile applications or services (including email
              communications), you agree to this Privacy and Security Statement.
              This Statement may change from time to time, and these changes may
              affect how we use the information described below, so please check
              the Privacy and Security Statement periodically for updates.
            </ContentText>
            <H3>NOTICE ABOUT HOW WE USE AND PROTECT PERSONAL INFORMATION</H3>
            <ContentText>
              We collect several types of information for our business
              operations, including but not limited to:
            </ContentText>
            <ul>
              <li>
                The Information you provide to us. When you navigate our
                websites and mobile applications or contact us, we may request
                or you may choose to provide us with certain information. This
                may include information by which you may be personally
                identified (“personal information”), such as name, employer,
                e-mail address or telephone number, and records and copies of
                your correspondence with us. For security purposes, we may also
                collect personal information from you if you visit one of our
                offices.
              </li>
              <li>
                Information collected from forms on our websites and mobile
                applications. This includes information provided at the time of
                registering to use portions of our websites and mobile
                applications or our products and services, posting material, or
                requesting further services. We may ask you for information when
                you report a problem with our websites and mobile applications,
                products or services.
              </li>
              <li>
                Usage details, IP addresses and cookies. As you navigate through
                and interact with our websites and mobile applications, we may
                automatically collect certain information about your equipment,
                browsing actions and patterns using common internet
                technologies, such as cookies and Web beacons. This may include
                details of your visits to our websites and mobile applications,
                including information about your connectivity, such as your IP
                address and browser information, location data, logs and other
                communication data, and the resources that you access and use on
                the websites and mobile applications. This information helps us
                to improve our websites and mobile applications and to deliver
                better and more personalized content and services by enabling us
                to estimate our audience size and usage patterns and recognize
                you when you return to our websites and mobile applications.
              </li>
              <li>
                Email marketing. As a current or previous customer or user of
                our products or services, you may periodically receive emails
                from us containing details about features of the products you
                use and/or about similar products or services that we offer.
                Such processing may include the placing of beacons, or pixels,
                that let us know if you have opened our email and how long the
                email was open. We do this in order to see which emails are
                effective and which are not, so that we can improve our content
                and services. It helps us make sure that you are getting emails
                from us that you find useful and relevant. In doing so, we will
                never track your location or device information.
              </li>
              <li>
                General business-related information. We may also receive other
                personal information from or about you in the ordinary course of
                our business.
              </li>
            </ul>
            <ContentText>
              We use information that we collect about you or that you provide
              to us, including personal information:
            </ContentText>
            <ul>
              <li>
                To present our websites and mobile applications, products and
                services to you and continuously improve upon them.
              </li>
              <li>
                To provide you with information, products or services that you
                request from us.
              </li>
              <li>
                To notify you about changes to our websites and mobile
                applications, products or services (including new offerings) and
                promotions or events.
              </li>
              <li>
                To maintain the integrity and security of our websites and
                mobile applications, products, and services.
              </li>
              <li>
                For our ordinary business operations, including human resources,
                recruiting, equity investments, and business research and
                outreach.
              </li>
            </ul>
            <ContentText>
              The Legal basis for the processing. We are not allowed to process
              personal data if we do not have a valid legal ground. Therefore,
              we will only process your personal data if:
            </ContentText>
            <ul>
              <li>
                the processing is necessary to perform our contractual
                obligations towards you or to take pre-contractual steps at your
                request, such as when we authenticate your log-in to our
                services or ensure that you only have access to the services and
                data we have agreed to provide you with;
              </li>
              <li>
                the processing is necessary to comply with our legal or
                regulatory obligations, such as tax reporting or regulatory
                requirements;
              </li>
              <li>
                the processing is necessary for the legitimate interests of
                Pumpjack Dataworks, and does not unduly affect your interests or
                fundamental rights and freedoms (see below);
              </li>
              <li>
                the processing is necessary for the performance of a task
                carried out in the public interest; or
              </li>
              <li>
                in some cases, and as may be requested from you from time to
                time, we have obtained prior consent.
              </li>
            </ul>
            <ContentText>
              To the extent that we process any special categories of data
              relating to you, we will do so because:
            </ContentText>
            <ul>
              <li>the data has been manifestly made public;</li>
              <li>
                the processing is necessary for the establishment, exercise or
                defense of a legal claim;
              </li>
              <li>
                the processing is necessary for reasons of substantial public
                interest; or
              </li>
              <li>
                you have given your explicit consent to us to process that
                information (where legally permissible).
              </li>
            </ul>
            <H3>DISCLOSURE OF YOUR INFORMATION</H3>
            <ContentText>
              Protecting your personal information is important to us and we
              neither rent nor sell your personal information to anyone. We may
              disclose or transfer personal information that we collect or you
              provide as described in this Privacy and Security Statement,
              including:
            </ContentText>
            <ul>
              <li>
                To a buyer or other successor in the event of a merger,
                acquisition, divestiture, restructuring, reorganization,
                dissolution or other sale or transfer of some or all of Pumpjack
                Dataworks’ assets, in which personal information held by
                Pumpjack Dataworks about our websites and mobile applications’
                users is among the assets transferred.
              </li>
              <li>
                To our subsidiaries, affiliates, agents, contractors, service
                providers and other third parties we use to support our business
                or collaborate with and who are bound by contractual obligations
                to keep personal information confidential and use it only for
                the purposes of providing services for or with us.
              </li>
              <li>
                To comply with any court order, law or legal process, including
                to respond to any government or regulatory request.
              </li>
              <li>
                To enforce or apply our Terms of Use and other agreements that
                govern the sale or use of our products and services.
              </li>
              <li>
                To protect the rights, property or security of Pumpjack
                Dataworks, our employees, our users or others.
              </li>
            </ul>
            <H3>ONWARD TRANSFER OF YOUR INFORMATION</H3>
            <ContentText>
              To facilitate our global operations, and in accordance with
              applicable laws, we may transfer your personal information to, and
              access your personal information from, our corporate offices,
              subsidiaries, and affiliates in the countries we operate,
              including the United States. Non-EEA countries may not offer the
              same level of personal data protection as EEA countries.
            </ContentText>
            <ContentText>
              If your personal data is transferred outside the EEA, we will put
              in place suitable safeguards to ensure that such transfer is
              carried out in compliance with applicable data protection rules.
              To ensure this level of protection for your personal information,
              we may use a data transfer agreement with the third-party
              recipient based on standard contractual clauses approved by the
              European Commission or ensure that the transfer is to a
              jurisdiction that is the subject of an adequacy decision by the
              European Commission or to the US under the EU-US Privacy Shield
              framework. You may request additional information in this respect
              and obtain a copy of the relevant safeguard by exercising your
              rights as set out below. Where Pumpjack Dataworks transfers
              personal data to other group companies, we rely on the standard
              contractual clauses.
            </ContentText>
            <H3>CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR INFORMATION</H3>
            <ContentText>
              We strive to provide you with choices regarding the personal
              information you provide to us. Here are a couple of ways you can
              have control over your information:
            </ContentText>
            <ContentText>
              Tracking Technologies and Advertising. Depending on your browser,
              you may be able to change settings to refuse all or some browser
              cookies, or to alert you when cookies are being sent. If you
              disable or refuse cookies, please note that some parts of the site
              may then be inaccessible or not function properly. In relation to
              email, you can disable most email tracking technology by
              preventing the loading of embedded pictures in the email. You can
              do this using your email software settings.
            </ContentText>
            <ContentText>
              Promotional Offers from the Company. If you do not wish to receive
              promotional e-mail messages from us, you can opt-out by sending us
              an e-mail at{" "}
              <a href="mailto:contact@pumpjackdataworks.com">
                contact@pumpjackdataworks.com
              </a>{" "}
              asking to be omitted from future e-mail distributions.
            </ContentText>
            <H3>DATA INTEGRITY AND PROPORTIONALITY</H3>
            <ContentText>
              We only collect and retain as much personal information as needed
              for specific, identified purposes described in this Privacy and
              Security Policy and we will not use it in any way that is
              incompatible with those purposes.
            </ContentText>
            <H3>WHAT ARE YOUR RIGHTS AND HOW CAN YOU EXERCISE THEM?</H3>
            <ContentText>
              You may have a right to access and to obtain a copy of your
              personal data as processed by Pumpjack Dataworks. If you believe
              that any information we hold about you is incorrect or incomplete,
              you may also request the correction of your personal data.
            </ContentText>
            <ContentText>You may also have the right to:</ContentText>
            <ul>
              <li>object to the processing of your personal data;</li>
              <li>request the erasure of your personal data;</li>
              <li>
                request restriction on the processing of your personal data;
                and/or
              </li>
              <li>
                withdraw your consent where Pumpjack Dataworks obtained your
                consent to process personal data (without this withdrawal
                affecting the lawfulness of any processing that took place prior
                to the withdrawal).
              </li>
            </ul>
            <ContentText>
              We will honor such requests, withdrawal or objection as required
              under applicable data protection rules but these rights are not
              absolute: they do not always apply and exemptions may be engaged.
              We will usually, in response to a request, ask you to verify your
              identity and/or provide information that helps us to better
              understand your request. If we do not comply with your request, we
              will explain why.
            </ContentText>
            <H3>EXERCISING YOUR RIGHTS</H3>
            <ContentText>
              To exercise the above rights, you may send an email to
              privacy@pumpjackdataworks.com. If you are not satisfied with how
              we process your personal data, please let us know and we will
              investigate your concern. Please raise any concerns by contacting
              our team at{" "}
              <a href="mailto:contact@pumpjackdataworks.com">
                contact@pumpjackdataworks.com
              </a>
              . If you are not satisfied with our response, you have the right
              to make a complaint to the data protection authority in the
              jurisdiction where you live or work, or in the place where you
              think an issue in relation to your data has arisen. The contact
              details of each Data Protection Authority can be found at the
              following website:{" "}
              <a href="http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm">
                http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm
              </a>
              .
            </ContentText>
            <H3>SECURITY</H3>
            <ContentText>
              We take your security seriously and take reasonable steps to
              protect and secure your personal information from unauthorized
              access, use, and disclosure. We have implemented adequate
              technical and organizational measures to protect personal data
              against unauthorized, accidental or unlawful destruction, loss,
              alteration, misuse, disclosure or access and against all other
              unlawful forms of processing. These security measures have been
              implemented taking into account the state of the art of the
              technology, their cost of implementation, the risks presented by
              the processing and the nature of the personal data, with
              particular care for sensitive data.
            </ContentText>
            <ContentText>
              The safety and security of your information also depends on you.
              Where we have given you (or where you have chosen) a password for
              access to certain parts of our websites and mobile applications,
              products, or services, you are responsible for keeping this
              password confidential. Please do not share your password with
              anyone.
            </ContentText>
            <ContentText>
              Although we take reasonable security measures to protect your
              personal information, for example, by using Secure Socket Layer
              encryption when you transmit your password, we cannot guarantee
              the security of your personal information transmitted to our
              websites and mobile applications. The transmission of information
              via the internet is not 100% secure and we cannot ensure or
              warrant the security of any information you transmit to us. We are
              not responsible for circumvention of any privacy settings or
              security measures contained on the websites and mobile
              applications.
            </ContentText>
            <H3>HOW LONG DO WE STORE YOUR DATA?</H3>
            <ContentText>
              We will only retain personal data for as long as necessary to
              fulfill the purpose for which it was collected or to comply with
              legal, regulatory or internal policy requirements.
            </ContentText>
            <H3>CHANGES TO OUR PRIVACY AND SECURITY STATEMENT</H3>
            <ContentText>
              We may amend this Privacy and Security Statement from time to
              time. We encourage you to revisit this page periodically to read
              the current version of this Statement in effect. If you have any
              concerns or questions about this Statement or if you would like to
              lodge a complaint, please send an email to{" "}
              <a href="mailto:contact@pumpjackdataworks.com">
                contact@pumpjackdataworks.com
              </a>
              .
            </ContentText>
            <H3>CHILDREN UNDER THE AGE OF 13</H3>
            <ContentText>
              Our websites and mobile applications are not intended for children
              under 13 years of age. If you become aware that your child has
              provided us with personal information without your consent, please
              contact us at{" "}
              <a href="mailto:contact@pumpjackdataworks.com">
                contact@pumpjackdataworks.com
              </a>{" "}
              and we will work to delete it. We do not knowingly collect
              personal information from children under 13.
            </ContentText>
            <H3>CONTACT INFORMATION</H3>
            <ContentText>
              If you have any questions or comments about this Privacy and
              Security Statement, please contact us by emailing us at{" "}
              <a href="mailto:contact@pumpjackdataworks.com">
                contact@pumpjackdataworks.com
              </a>{" "}
              or by writing to us at:
            </ContentText>
            <ContentText>
              Pumpjack Dataworks, Inc.
              <br />
              1401 Foch Street, Suite 150
              <br />
              Fort Worth, TX 76107
            </ContentText>
            <H2>Privacy Shield Notice</H2>
            <ContentText>Effective Date: 4/14/20</ContentText>
            <ContentText>
              Pumpjack Dataworks, Inc. (“Pumpjack Dataworks”, “we”, or “us”)
              complies with the EU-U.S. and Swiss-U.S. Privacy Shield Frameworks
              as set forth by the U.S. Department of Commerce regarding the
              transfer of personal data from the EU and Switzerland to the U.S.
              (“Personal Information”). For purposes of this Privacy Shield
              Notice, “Personal Information” is information that identifies,
              directly or indirectly, a client or consumer in the EU or
              Switzerland. Pumpjack Dataworks has self-certified to the U.S.
              Department ofCommerce and declared its commitment to adhere to the
              Privacy Shield Principles of Notice; Choice; Accountability for
              Onward Transfer; Security; Data Integrity and Purpose Limitation;
              Access; and Recourse, Enforcement, and Liability (the
              “Principles”).
            </ContentText>
            <ContentText>
              You can learn more about Privacy Shield at{" "}
              <a href="https://www.privacyshield.gov">
                https://www.privacyshield.gov
              </a>
              . Pumpjack Dataworks’ self-certification to the Privacy Shield is
              subject to the investigatory and enforcement authority of the
              Federal Trade Commission.
            </ContentText>
            <H3>Personal Information We Process</H3>
            <ContentText>
              From clients (i.e., users of pumpjackdataworks.com and subscribers
              to Pumpjack Dataworks software and services), we collect the
              categories of information, which may include Personal Information,
              described in “THE INFORMATION WE COLLECT” section of our Privacy
              Policy. We process such information for the purposes describedin
              the “DISCLOSURE OF YOUR INFORMATION” section of our Privacy
              Policy. From client customers (i.e., users of a website developed
              and operated by a Pumpjack Dataworks client), we may collect name,
              contact information, IP address, geolocation information, purchase
              information, and transaction data.
            </ContentText>
            <H3>Accessing Personal Information</H3>
            <ContentText>
              The Privacy Shield Principles provide individuals located in the
              EU whose Personal Information we process the rightto access their
              Personal Information and to review, correct, amend, or delete
              their Personal Information. Clients who would like to access their
              Personal Information may do so by logging into the Pumpjack
              Dataworks user interface or emailing us at{" "}
              <a href="mailto:contact@pumpjackdataworks.com">
                contact@pumpjackdataworks.com
              </a>
              . Client customers who would like to access their Personal
              Information should request access from the Pumpjack Dataworks
              client whose website they use.
            </ContentText>
            <H3>Transfers to Third Parties and Your Choices</H3>
            <ContentText>
              As described in the “DISCLOSURE OF YOUR INFORMATION” and “ONWARD
              TRANSFER OF YOUR INFORMATION” sections of our Privacy Policy, we
              may disclose and transfer client Personal Information to third
              parties. We will also disclose client customer Personal
              Information to third parties (e.g.service providers). We
              contractually require those third parties to provide the same
              level of protections to Personal Information as required under the
              Principles. Pumpjack Dataworks will remain liable under the
              Principles if a third party processes Personal Information in a
              manner inconsistent with the Principles, unless Pumpjack Dataworks
              proves that it is not responsible for the event giving rise to the
              damage. In accordance with our legal obligations, we may also
              transfer, subject to a lawful request, Personal Information to
              public authorities for law enforcement or national security
              purposes.
            </ContentText>
            <H3>Contacting Us, Complaints and Dispute Resolution</H3>
            <ContentText>
              We encourage EU individuals who have questions or complaints about
              how we process their Personal Information under Privacy Shield to
              contact us at{" "}
              <a href="mailto:contact@pumpjackdataworks.com">
                contact@pumpjackdataworks.com
              </a>
              . We will work to resolve your issue as quickly as possible, but
              in any event within 45 days of receipt. If you have anunresolved
              privacy or data use complaints that we have not addressed
              satisfactorily, please contact, free of charge, our dispute
              resolution provider, JAMS, at{" "}
              <a href="https://www.jamsadr.com/file-an-eu-us-privacy-shield-or-safe-harbor-claim">
                https://www.jamsadr.com/file-an-eu-us-privacy-shield-or-safe-harbor-claim
              </a>
              . If you are an EU or Swiss individual and unable to resolve any
              complaints through any of the above methods, you may be able to
              invoke binding arbitration through a Privacy Shield panel, in
              accordance with the Privacy Shield Framework at{" "}
              <a href="https://www.privacyshield.gov/article?id=How-to-Submit-a-Complaint">
                https://www.privacyshield.gov/article?id=How-to-Submit-a-Complaint
              </a>
            </ContentText>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Section>
  </Layout>
)

export default Privacy
